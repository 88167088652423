import Footer from "../Footer";
import Header from "../Header";
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import { getActiveEvents } from "../../utils/apiUtils";
import { Button } from "@mui/material";
import Loader from "../../utils/Loader";
import axios from 'axios';




export default function CreateAtt() {
  const [events, setEvents] = useState([]);
  const [attendees, setAttendees] = useState([])
  const [event, setEvent] = useState(0);
  const [form, setForm] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [paid, setPaid] = useState(false);
  const [amount, setAmout] = useState(0);
  //alerts
  const [disable, setDisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [userId, setUserId] = useState('');
  const url = 'https://dev.pmwevents.com'
  // const url = 'http://localhost:4100'



  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);
    getActiveEvents().then((data) => {
      setEvents(data)
    }).catch((err) => {
      console.log(`Could not get events: ${err}`)
    })
  }, [])

  const handleForm = (eventId) => {
    if (eventId === '-SELECT EVENT-') {
      setMessage('Please select an event')
      setSeverity('warning')
      setShowAlert(true);
      setShowLoader(false)
      setTimeout(() => {
        setShowAlert(false);
      }, 2000)
    } else {
      setEvent(parseInt(eventId))
      setForm(true)
    }
  }

  const handleAttendee = async (index, field, value) => {
    let localArray = attendees

    if (localArray.length < parseInt(index) + 1) {
      localArray.push({ name: '', lastName: '', email: '', phone: '', occupation: '', postcode: '' })
      localArray[localArray.length - 1][field] = value
    } else {
      localArray[index][field] = value
    }

    setAttendees(localArray)
  }


  //Create attendee
  const createAttendee = () => {
    setDisabled(true)
    setShowLoader(true)
    let send = true;

    for (let a = 0; a < attendees.length; a++) {
      let fieldsToEncrypt = ['name', 'email', 'phone'];

      Object.keys(attendees[a]).forEach((field) => {
        if (attendees[a][field] === '') {
          setShowLoader(false)
          setMessage('All fields are mandatory')
          setSeverity('warning')
          setShowAlert(true);
          setShowLoader(false)
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false)
          }, 2000)
          send = false;
        }
        else {
          attendees[a].eventId = event;
          attendees[a].attendeeId = `${attendees[a].eventId}-adm-${Math.floor(1000 + Math.random() * 9000)}`
          attendees[a].paidAmount = amount / attendees.length
        }
      })
    }
    if (send) {
      let data = {
        userId: userId,
        attendees: attendees,
      }
      axios.post(`${url}/events/add-att`, data)
        .then((res) => {
          console.log(res.data)
          setShowLoader(false)
          setMessage('Attendee(s) added to the event, they will receive their email with the ticket soon')
          setSeverity('success')
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false)
            setAttendees([]);
            setQuantity(1);
            let inputs = document.getElementsByTagName('input')
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].value = ""
            }
          }, 2000)

        }).catch((err) => {
          console.log(err)
          setShowLoader(false)
          setMessage('Something went wrong, please try again')
          setSeverity('error')
          setShowLoader(false)
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setDisabled(false)
          }, 2000)

        })
    }

  }

  const handlePaid = (sel) => {
    if (sel == 'false') {
      setPaid(false)
    } else {
      setPaid(true)
    }
  }

  return (
    <main className="events__management">
      <Header />
      <section className="download__main">
        <h2>Please select an active event to add attendees</h2>
        <select onChange={(e) => handleForm(e.target.value)}>
          <option>-SELECT EVENT-</option>
          {events.map((event) => {
            return (
              <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
            )
          })}
        </select>
        {form ? (
          <section className='add__form'>
            <h3>All fields are mandatory</h3>
            <label>Quantity</label>
            <select onChange={(e) => setQuantity(parseInt(e.target.value))}>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            {quantity > 0 ? (
              Array.from(Array(quantity)).map((att, index) => {
                return (
                  <>
                    <h6>Attendee {index + 1}</h6>
                    <label>First Name</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'name', e.target.value)} />
                    <label>Last Name</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'lastName', e.target.value)} />
                    <label>Email</label>
                    <input type='email' onChange={(e) => handleAttendee(index, 'email', e.target.value)} />
                    <label>Telephone</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'phone', e.target.value)} />
                    <label>Occupation</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'occupation', e.target.value)} />
                    <label>Postcode</label>
                    <input type='text' onChange={(e) => handleAttendee(index, 'postcode', e.target.value)} />
                  </>
                )
              })
            ) : null}
            <label>Has the client paid?</label>
            <select onChange={(e) => handlePaid(e.target.value)}>
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
            {paid ? (
              <>
                <label>How much?</label>
                <input type='number' onChange={(e) => setAmout(e.target.value)} />
              </>
            ) : null}
            <Button sx={{ alignSelf: 'center', margin: '2% auto' }} variant='contained' color='primary' disabled={disable} onClick={createAttendee}>Add Attendee</Button>
          </section>
        ) : null}
        {showLoader ? <Loader /> : null}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}
      </section>
      <Footer />
    </main>
  )
}