import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from "react";
import { Button, Alert, Stack } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { decryptData } from '../../utils/cryptoUtils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
  }
};

const copiedAlert = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}


export default function ModalCheck(props) {
  const [data, setData] = useState('')
  const [user, setUser] = useState('')
  const url = 'https://dev.pmwevents.com';
  // const url = 'http://localhost:4100'
  const [mobile, setMobile] = useState(false);

  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');
  const [noScan, setNoScan] = useState(true);
  const [copyAlert, setCopyAlert] = useState(false);

  useEffect(() => {
    let id = localStorage.getItem('user')
    setUser(id)

    if (window.innerWidth < "1300px") {
      setMobile(true)
    }
  }, [window.innerWidth])

  const handleNoScan = () => {
    setNoScan(!noScan)
  }

  const copy = (orderId) => {
    navigator.clipboard.writeText(orderId)
    setCopyAlert(true)
    setTimeout(() => {
      setCopyAlert(false)
    },1000)
  }



  const checkIn = () => {
    console.log(data)
    if (data !== props.attendee) {
      setMessage('User you are trying to scan does not match')
      setShowAlert(true)
      setSeverity('warning')
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      let checkInData = {
        user: user,
        attendeeId: data
      }
      axios.post(`${url}/checkin`, checkInData)
        .then((res) => {
          console.log(res.data)
          let checked = res.data;
          checked.firstName = decryptData(checked.firstName, process.env.REACT_APP_ATT_KEY)
          checked.lastName = decryptData(checked.lastName, process.env.REACT_APP_ATT_KEY)
          setMessage(`Attendee: ${checked.firstName} ${checked.lastName} has been checked in with Ticket Type: ${res.data.ticket}`)
          setShowAlert(true)
          setSeverity('success')
          setTimeout(() => {
            setShowAlert(false)
            props.handleClose()
            props.rel()
          }, 4000)
        }).catch((err) => {
          console.log(`Could not check the attendee in: ${err}`)
          setMessage('Something went wrong, please try again')
          setShowAlert(true)
          setSeverity('error')
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)

        })

    }
  }

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Please enter the ticket number
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Stack>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} >
            <label><strong onClick={() => copy(props.attendee)}>{props.attendee}</strong> Cannot find the ticket?</label>
            <KeyboardArrowDownIcon onClick={handleNoScan} />
          </Stack>
          {noScan ? <input type='text' onChange={(e) => setData(e.target.value)} style={{ margin: 'auto' }} /> : null}
          {copyAlert ? (
            <Alert severity='primary' sx={copiedAlert} >
              Copied to clipboard
            </Alert>
          ) : null}
          {showAlert ? (
            <Alert severity={severity} sx={{ marginTop: '20%' }} variant="filled">
              {message}
            </Alert>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
              <Button color='error' variant='outlined' onClick={() => props.handleClose()}>Return</Button>
              <Button color='success' variant='contained' onClick={checkIn}>Check-In</Button>
            </Stack>
          )}
        </Stack>

      </Typography>
    </Box>

  )

}