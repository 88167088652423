import Footer from "../Footer";
import Header from "../Header";
import '../../styles/dash.scss';
import { useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Future from "./Future";
import UpdateIcon from '@mui/icons-material/Update';
import CompareIcon from '@mui/icons-material/Compare';
import LockIcon from '@mui/icons-material/Lock';
import Passed from "./Passed";
import Compare from "./Compare";


export default function Dash() {
  // const [value, setValue] = useState('1');
  const [value, setValue] = useState('1');


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <main className="dash">
      <Header />
      <section className="dash__main">
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
            <Tab label="Future Events" value="1" icon={<UpdateIcon />}/>
            <Tab label="Past Events" value="2" icon={<LockIcon />}/>
            <Tab label="Compare Events" value="3"  icon={<CompareIcon />}/>
          </TabList>
        </Box>
        <TabPanel value="1">
          <Future />
        </TabPanel>
        <TabPanel value="2"><Passed /></TabPanel>
        <TabPanel value="3">
          <Compare />
        </TabPanel>

      </TabContext>
    </Box>
      </section>
      <Footer />
    </main>
  )
}