import "../../styles/dash.scss";
import { useEffect, useState } from "react";
import { getAllEvents, getActiveEvents } from "../../utils/apiUtils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Loader from "../../utils/Loader";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import Alert from "@mui/material/Alert";

export default function Future() {
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [analytics, setAnalytics] = useState([]);
  const url = "https://dev.pmwevents.com";
  // const url = 'http://localhost:4100';
  const [showData, setShowData] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [adminSales, setAdminSales] = useState([]);
  const [noSales, setNoSales] = useState(false);

  useEffect(() => {
    getActiveEvents()
      .then((data) => {
        setEvents(data);
      })
      .catch((err) => {
        console.log(`could not get events: ${err}`);
      });
  }, []);

  const handleEventSelect = (id) => {
    setShowData(false);
    setEventId(parseInt(id));
    for (let e = 0; e < events.length; e++) {
      if (id == events[e].eventId) {
        setEventName(events[e].eventName);
      }
    }
    setShowLoader(true);
    axios
      .post(`${url}/events/analytics`, { id: parseInt(id) })
      .then((res) => {
        console.log(res.data)
        let salesData = res.data.sales;
        if (salesData[0].length === 0 && salesData[1].length === 0) {
          console.log("no sales");
          setShowLoader(false);
          setNoSales(true);
        } else {
          console.log(res.data);
          setAnalytics(res.data);
          setSalesData(res.data.web);
          setAdminSales(res.data.admin);
          setDatesData(res.data.dates);
          // LOADER OFF LOADS DATA
          setShowLoader(false);
          setShowData(true);
        }
      })
      .catch((err) => {
        console.log(`Could not get the analytics: ${err}`);
      });
  };

  // CHART
  let sales = {
    series: [
      {
        name: "Web Sales",
        data: salesData,
      },
      {
        name: "Admin Tickets",
        data: adminSales,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        width: 750,
        // background: "#fff",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: datesData,
      },
      yaxis: {
        title: {
          text: "Ticket Sales per Day",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "  Tickets";
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 750,
        options: {
          width: 450,
        },
      },
    ],
  };

  // Attendees
  let attendees = {
    series: [
      parseInt(analytics.capacity) - parseInt(analytics.active),
      parseInt(analytics.active),
      parseInt(analytics.totalSales) - parseInt(analytics.active),
    ],
    options: {
      chart: {
        width: 380,
        type: "pie",
        background: "#fff",
      },
      labels: ["Unsold", "Active", "Cancelled"],
      dataLabels: {
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <main className="dash">
      <section className="dash__main">
        <h1>Future Events Dashboard</h1>
        {events.length > 0 ? (
          <FormControl className="form--select">
            <InputLabel id="select-label">Select an Event</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={eventId}
              label="Age"
              onChange={(e) => handleEventSelect(e.target.value)}
              sx={{ backgroundColor: "#fff" }}
            >
              {events.map((event) => {
                return (
                  <MenuItem key={event.eventId} value={event.eventId}>
                    {event.eventName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <h2>No events could be found</h2>
        )}
        {showLoader ? <Loader /> : null}
        {showData ? (
          <section className="dash__data">
            <h1>
              Data for event: <span>{eventName}</span>
            </h1>
            <section className="dash__row">
              <div className="dash__card">
                <h3>£{parseFloat(analytics.profit).toFixed(2)}</h3>
                <h6>Sales</h6>
              </div>
              <div className="dash__card">
                <h3>{analytics.active}</h3>
                <h6>Attendees</h6>
              </div>
              <div className="dash__card">
                <h3>{analytics.totalSales}</h3>
                <h6>Total Sales</h6>
              </div>
            </section>
            <section className="dash__row">
              <ReactApexChart
                options={sales.options}
                series={sales.series}
                type="bar"
                width={600}
              />
              <ReactApexChart
                options={attendees.options}
                series={attendees.series}
                width={400}
                type="pie"
              />
            </section>
          </section>
        ) : null}
        {noSales ? (
          <Alert
            severity="warning"
            sx={{ marginTop: "2%", with: "80%" }}
            variant="filled"
          >
            No Sales for this event
          </Alert>
        ) : null}
      </section>
    </main>
  );
}
