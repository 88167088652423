import { IconButton } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useEffect, useState } from "react";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { Alert } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Attendees(props) {
  const url = "https://dev.pmwevents.com";
  // const url = "http://localhost:4100";
  const [user, setUser] = useState("");
  const attendee = props.att;
  const today = moment();
  const [checked, setChecked] = useState(false);
  const [openUncheck, setOpenUncheck] = useState(false);

  //Alert
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [alert, setAlert] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    let id = localStorage.getItem("user");
    setUser(id);
    let difference = today.diff(moment(props.date), "days");
    let eventDay = `day${difference + 1}`;
    let checkins = props.att.checkIn;

    if (checkins != null) {
      for (let c = 0; c < checkins.length; c++) {
        Object.keys(checkins[c]).forEach((key) => {
          if (key === eventDay) {
            setChecked(true);
          }
        });
      }
    }
  }, []);

  const handleAlert = (
    message,
    severity,
    closeModal = false,
    duration = 4000
  ) => {
    setMessage(message);
    setSeverity(severity);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
      setMessage("");
      setSeverity("");
      setDisable(false);
      if (closeModal) {
        setOpenUncheck(false);
        props.rel();
      }
    }, duration);
  };

  const handleUncheckAttendee = async () => {
    setDisable(true);
    let reqBody = {
      user: user,
      attendeeId: props.att.attendeeId,
    };

    await axios
      .post(`${url}/checkin/uncheck-attendee`, reqBody)
      .then((res) => {
        handleAlert("Attendee Successfully Unchecked", "success", true);
      })
      .catch((err) => {
        console.log(err);
        handleAlert("Something went wrong - Please try again.", "error");
      });
  };

  return (
    <>
      <tr>
        <td>{attendee.attendeeId}</td>
        <td>{attendee.name}</td>
        <td>{attendee.lastName}</td>
        <td>{attendee.ticketType}</td>
        {checked ? (
          <td>
            <IconButton>
              <CheckCircleIcon
                color="success"
                fontSize="large"
                onClick={() => setOpenUncheck(true)}
              />
            </IconButton>
          </td>
        ) : (
          <td>
            <IconButton>
              <QrCodeIcon
                fontSize="large"
                color="primary"
                onClick={() => props.handleOpen(attendee.attendeeId)}
              />
            </IconButton>
          </td>
        )}
      </tr>
      <Modal open={openUncheck}>
        <Box sx={style} className="uncheck-attendee-modal">
          {/* <section className="uncheck-attendee-modal"> */}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Uncheck Attendee
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you wish to uncheck this attendee?
          </Typography>
          <section className="alert-section">
            {alert ? <Alert severity={severity}>{message}</Alert> : null}
          </section>
          <section className="btn-section">
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenUncheck(false)}
              disabled={disable}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleUncheckAttendee}
              disabled={disable}
            >
              Yes
            </Button>
          </section>
          {/* </section> */}
        </Box>
      </Modal>
    </>
  );
}
