import Footer from "../Footer";
import Header from "../Header";
import '../../styles/sbc.scss';
import sbc from '../../images/sbc_logo.webp';
import { useState, useEffect } from "react";
import { getSbcAtts, getSbcActive } from "../../utils/apiUtils";
import moment from "moment";
import { Alert, Button } from "@mui/material";
import { decryptData } from "../../utils/cryptoUtils";
import SbcAtts from "./attendees/SbcAtts";
import DownloadIcon from '@mui/icons-material/Download';

//EXCEL EXPORTS
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem',
  '@media(max-width: 700px)': {
    width: '80vw',
    maxHeight: '80vh',
    overflowY: 'scroll',
  }
};



export default function SbcManAt() {
  // const url = 'http://localhost:4100';
  const url = 'https://dev.pmwevents.com'

  const [reload, setReload] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [userId, setUserId] = useState('');
  const [eventId, setEventId] = useState('');
  const [events, setEvents] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [searchParam, setsearchParam] = useState([]);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState('');



  useEffect(() => {
    let id = localStorage.getItem('user');
    setUserId(id);
    getSbcActive()
      .then((data) => {
        console.log(data)
        setEvents(data)
        if (data.length === 1) {
          setEventId(data[0].eventId)
          getAttendees(data[0].eventId)
        }
      }).catch((err) => {
        console.log(err)
      })
  }, [reload])

  const rel = () => {
    setReload(!reload)
  }

  const getAttendees = async (id) => {
    await getSbcAtts(id)
      .then((data) => {
        console.log(data)
        if (data.length === 0) {
          setMessage('No Tickets Bought for this event yet');
          setSeverity('warning');
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000)

        } else {
          let atts = data;
          for (let a = 0; a < atts.length; a++) {
            atts[a].name = decryptData(atts[a].name, process.env.REACT_APP_ATT_KEY)
            atts[a].email = decryptData(atts[a].email, process.env.REACT_APP_ATT_KEY)
            atts[a].phone = decryptData(atts[a].phone, process.env.REACT_APP_ATT_KEY)
            atts[a].lastName = decryptData(atts[a].lastName, process.env.REACT_APP_ATT_KEY)
            atts[a].address = decryptData(atts[a].address, process.env.REACT_APP_ATT_KEY)
          }
          setAttendees(atts)
        }
      }).catch((err) => {
        console.log(err)
        setMessage('Something went wrong, please try again')
        setSeverity('error')
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000)
      })
  }

    //Search attendee
    const searchAtt = (param) => {
      let searchParam = param;
      let localAttendees = [];
  
      if (searchParam.length >= 2) {
        for (let a = 0; a < attendees.length; a++) {
          if (attendees[a].name.toLowerCase().includes(searchParam.toLowerCase())) {
            localAttendees.push(attendees[a]);
          }
        }
      }
      setsearchParam(localAttendees);
    }
  
    const exportExcel = () => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      // const fileExtension = '.xlsx';
  
      const excelExportsData = [];
  
      attendees.forEach((obj) => {
        let newObj = {};
  
        Object.keys(obj).forEach((line) => {
          newObj[line] = obj[line];
        });
        excelExportsData.push(newObj);
      });
  
      const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelExportsData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
  
        FileSaver.saveAs(
          data, `Attendees for SBC`
        )
      }
  
      exportToExcel();
    }
  

  return (
    <main className="sbc__select">
      <Header />
      <section className='sbc__selection'>
        <img src={sbc} alt="sbc" />
        {events.length === 1 ? (
          <>
            <h2>Manage SBC Attendees {moment(events[0].eventDate).format('DD-MM-YYYY')}</h2>
          </>
        ) : null}
        <input type="text" placeholder='Search By Name:' onChange={(e) => searchAtt(e.target.value)}/>
        {searchParam.length > 0 ? (
              <table>
                <thead>
                  <th>Order ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Ticket Type</th>
                  <th>Delete</th>
                  <th>Change Event</th>
                  <th>Resend Ticket</th>
                  <th>Edit</th>
                </thead>
                <tbody>
                  {searchParam.map((att, index) => {
                    let backColor = "";
                    if (att.status === "cancelled") {
                      backColor = '#E8160C'
                    }
                    return (
                      <SbcAtts att={att} index={index} rel={rel} style={style} event={eventId} userId={userId}/>
                    )
                  })}
                </tbody>
              </table>
            ) : null}
        {attendees.length > 0 ? (
          <table>
            <thead>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Ticket Type</th>
              <th>Delete</th>
              <th>Upgrade Ticket</th>
              <th>Resend Ticket</th>
              <th>Edit</th>
            </thead>
            <tbody>
              {attendees.map((att, index) => {
                return (
                  <SbcAtts att={att} index={index} rel={rel} style={style} event={eventId} userId={userId}/>
                )
              })}
            </tbody>
          </table>
        ) : null}
            <Button color='primary' startIcon={<DownloadIcon />} variant="contained" onClick={exportExcel} sx={{marginBottom: '2%'}}>Download</Button>

        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: '2%' }} variant="filled">
            {message}
          </Alert>
        ) : null}
      </section>
      <Footer />
    </main>
  )
}